import React, { useState, useEffect, useRef } from "react";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import Header from "./Header";

import ChatSidebar from "./components/Navigation/ChatSidebar";

import {
  getAccountName,
  getAccountImage,
  getAccountEmail,
} from "./utilities/dbUtils";

import {
  ChatBubbleLeftEllipsisIcon,
  CogIcon,
  SparklesIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

const Typewriter = ({ text, speed = 50 }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex(index + 1);
      }, speed);
      return () => clearTimeout(timeout); // Cleanup the timeout on unmount or index update
    }
  }, [index, text, speed]);

  return <p className="p-2 bg-gray-100 text-gray-700">{displayedText}</p>;
};

// Define a variable for the background of the message display area
const messageAreaBg = "bg-gray-100"; // Change this to any Tailwind background class

function AppCanvas({ currentUser, useTabs }) {
  const [conversation, setConversation] = useState([]);
  const [input, setInput] = useState("");

  const { tabs, currentTab, addTab, removeTab, selectTab } = useTabs;
  const currentTabId = tabs[currentTab];

  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);

  const user = {
    name: getAccountName(currentUser),
    email: getAccountEmail(currentUser),
    imageUrl: getAccountImage(currentUser),
  };

  // Automatically adjust the height of the textarea as the user types
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset the height first
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust height based on content
    }
  }, [input]);

  // Scroll to the bottom when new messages are added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!input.trim()) return; // Avoid empty messages

    const newMessage = {
      id: Date.now(), // Use current time as the id
      text: input,
      fromUser: true, // Message is from the user
    };

    setConversation((prevConversation) => [...prevConversation, newMessage]);
    setInput("");

    setTimeout(() => {
      const agentMessage = {
        id: Date.now(), // Another unique id for agent's message
        text: "You know nothing Jon Snow.",
        fromUser: false, // Message is from the agent
      };

      setConversation((prevConversation) => [
        ...prevConversation,
        agentMessage,
      ]);
    }, 1000);
  };

  const handleKeyDown = (e) => {
    // Check if the key is "Enter" and prevent adding a new line
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent newline insertion
      handleSubmit(e); // Submit the form
    }
  };

  return (
    <div
      className="workflow-canvas"
      style={{ overflow: "hidden", height: "100vh" }}
    >
      <Header
        className="fixed top-0 left-0 right-0 z-20"
        setActiveModal={"setActiveModal"}
        currentUser={currentUser}
        tabs={tabs}
        currentTabId={currentTabId}
        selectTab={selectTab}
      />

      <div className="chat-application h-screen overflow-hidden flex bg-gray-100">
        {/* Sidebar (Left Column) */}
        <div className="flex w-1/5 flex-col h-full bg-blue-100">
          <ChatSidebar />
        </div>

        {/* Chat Container */}
        <div className="flex flex-col w-full max-w-[800px] h-full mx-auto pt-16">
          {/* Chat Messages */}
          <div className={`flex-grow overflow-y-auto p-4 ${messageAreaBg}`}>
            {conversation.length === 0 ? (
              // Show welcome message when there are no messages flex
              <div className="flex flex-col justify-center items-center h-screen flex-grow p-10">
                <h3 className="text-xl text-center">Welcome to</h3>
                <h1
                  className="text-5xl font-bold text-center"
                  style={{
                    background: "linear-gradient(45deg, red, yellow)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  twinLab-Agent
                </h1>
                <p className="py-4 text-center">
                  Ask your application to get started.
                </p>
              </div>
            ) : (
              conversation.map((message) => (
                <div
                  key={message.id}
                  className={`message mb-4 max-w-xs flex items-end ${
                    message.fromUser ? "ml-auto flex-row-reverse" : "mr-auto"
                  }`}
                >
                  {/* User message */}
                  {message.fromUser ? (
                    <>
                      <img
                        src={user.imageUrl}
                        alt={user.name}
                        className="h-8 w-8 rounded-full ml-2 transform -translate-y-1"
                      />
                      <p className="p-2 rounded-lg bg-gray-200 text-gray-700 mr-2">
                        {message.text}
                      </p>
                    </>
                  ) : (
                    // Agent message
                    <>
                      <div className="ml-2 mr-2 flex items-center justify-center h-8 w-8 rounded-full bg-blue-900 transform -translate-y-1">
                        <span className="text-lg font-bold text-white">tL</span>
                      </div>

                      <Typewriter text={message.text} speed={30} />
                    </>
                  )}
                </div>
              ))
            )}
            {/* Scroll to here */}
            <div ref={messagesEndRef} />
          </div>

          {/* Message Input - fixed at the bottom */}
          <div className="p-4 bg-gray-100">
            <form onSubmit={handleSubmit} className="flex">
              <textarea
                ref={textareaRef}
                placeholder="Ask your application..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown} // Listen for Enter key press
                className="flex-1 px-4 py-4 rounded-lg border text-gray-800 border-gray-400 focus:outline-none bg-gray-200 resize-none overflow-y-auto leading-relaxed"
                style={{
                  maxHeight: "25vh",
                  lineHeight: "1.5",
                  minHeight: "40px",
                }} // Limit height to 30% of window height and add line spacing
                rows={1} // Start with 1 row, will grow automatically with content
              />
              <button
                type="submit"
                className="ml-2 bg-gray-400 text-white p-3 rounded-full flex items-center justify-center"
                style={{ width: "48px", height: "48px" }}
              >
                <ArrowUpRightIcon className="h-5 w-5" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppCanvas;
