import {
  AccountInfo,
  ProjectInfo,
  OrganizationInfo,
  UserInfo,
  WorkflowInfo,
} from "../interfaces";
import {
  projects,
  organizations,
  accounts,
  cognito,
  workflows,
  models,
  apps,
} from "../mockData";

// FUNCTIONS

export function getAccount(userId: string): AccountInfo {
  const account = accounts.find((account) => account.id === userId);
  if (!account) {
    throw new Error(`Account with id ${userId} not found`);
  }
  return account;
}

export function getAccountName(userId: string): string {
  return getAccount(userId).name;
}

export function getAccountEmail(userId: string): string {
  return getAccount(userId).email;
}

export function getAccountImage(userId: string): string {
  const user = cognito.find((user) => user.id === userId);
  if (!user || !user.imageUrl) {
    throw new Error(`Image for user id ${userId} not found`);
  }
  return user.imageUrl;
}

export function getOrganization(orgId: string): OrganizationInfo {
  const organization = organizations.find(
    (organization) => organization.id === orgId
  );
  if (!organization) {
    throw new Error(`Organization with id ${orgId} not found`);
  }
  return organization;
}

export function getOrganizationMembers(orgId: string): string[] {
  const organization = getOrganization(orgId);
  const members = Object.keys(organization.members);
  return members;
}

export function getOrganizationFromUser(userId: string): string {
  const organization = organizations.find((org) =>
    org.members.hasOwnProperty(userId)
  );
  if (!organization) {
    throw new Error(`Organization for user with id ${userId} not found`);
  }
  return organization.id;
}

export function getProject(projectId: string): ProjectInfo {
  const project = projects.find((project) => project.id === projectId);
  if (!project) {
    throw new Error(`Project with id ${projectId} not found`);
  }
  return project;
}

export function getProjectName(projectId: string): string {
  return getProject(projectId).name;
}

export function getProjectMembers(projectId: string): string[] {
  const project = getProject(projectId);
  const members = Object.keys(project.members);
  return members;
}

export function getProjectRole(userId: string, projectId: string) {
  const project = getProject(projectId);
  return project.members[userId];
}

export function getWorkflow(workflowId: string): WorkflowInfo {
  const workflow = workflows.find((workflow) => workflow.id === workflowId);
  if (!workflow) {
    throw new Error(`Workflow with id ${workflowId} not found`);
  }
  return workflow;
}

export function getWorkflowName(workflowId: string): string {
  return getWorkflow(workflowId).name;
}

export function getRecommendedMembers(
  currentTeam: AccountInfo[],
  recommendedTeam: AccountInfo[]
): AccountInfo[] {
  return recommendedTeam.filter(
    (user) => !currentTeam.find((member) => member.id === user.id)
  );
}

export function getUserDetails(userId: string, projectId: string): UserInfo {
  const user = {
    ...getAccount(userId),
    imageUrl: getAccountImage(userId),
    role: getProjectRole(userId, projectId),
  };
  if (!user) {
    throw new Error(`User with id ${userId} not found`);
  }
  return user;
}

export function getUsersDetails(
  userIds: string[],
  projectId: string
): UserInfo[] {
  return userIds
    .map((id) => getUserDetails(id, projectId))
    .filter((user) => user !== undefined) as UserInfo[];
}

export function getTabType(tabId: string): string {
  if (!tabId || typeof tabId !== "string") {
    throw new Error("Invalid input: tabId must be a non-empty string.");
  }

  const firstLetter = tabId.charAt(0).toLowerCase();
  let tabType: string | undefined;

  switch (firstLetter) {
    case "w":
      tabType = "workflow";
      break;
    case "m":
      tabType = "model";
      break;
    case "a":
      tabType = "app";
      break;
    default:
      throw new Error("Invalid tab type.");
  }

  if (!tabType) {
    throw new Error(`Tab with id ${tabId} not found`);
  }

  return tabType;
}

export function getTabName(tabId: string): string {
  if (!tabId || typeof tabId !== "string") {
    throw new Error("Invalid input: tabId must be a non-empty string.");
  }

  const tabType: string = getTabType(tabId);
  let tabName: string | undefined;

  switch (tabType) {
    case "workflow":
      tabName = workflows.find((workflow) => workflow.id === tabId)?.name;
      break;
    case "model":
      tabName = models.find((model) => model.id === tabId)?.name;
      break;
    case "app":
      tabName = apps.find((app) => app.id === tabId)?.name;
      break;
    default:
      throw new Error("Invalid tab type.");
  }

  if (!tabName) {
    throw new Error(`Tab with id ${tabId} not found`);
  }

  return tabName;
}
