import { Position } from "@xyflow/react";

// generateHandles.js
const generateHandles = (
  numTargets,
  numSources,
  targetTypes,
  sourceTypes,
  headerHeight
) => {
  const handles = [];
  const handleDiv = 30 * (Math.max(numTargets, numSources) + 1);
  const offsetTarget = handleDiv / (numTargets + 1);
  const offsetSource = handleDiv / (numSources + 1);

  // Generate target handles
  for (let i = 1; i <= numTargets; i++) {
    handles.push({
      id: `target_${i}`,
      type: "target",
      position: Position.Left,
      style: { top: headerHeight + offsetTarget * i },
      dataType: targetTypes[i - 1] || "NullType",
      isValidConnection: false, // Initially not connected
    });
  }

  // Generate source handles
  for (let i = 1; i <= numSources; i++) {
    handles.push({
      id: `source_${i}`,
      type: "source",
      position: Position.Right,
      style: { top: headerHeight + offsetSource * i },
      dataType: sourceTypes[i - 1] || "NullType",
      isValidConnection: false, // Initially not connected
    });
  }

  return {
    handles,
    handleDiv,
    offsetTarget,
    offsetSource,
    handleDiv,
  };
};

export default generateHandles; // Default export
