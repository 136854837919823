import React from "react";
import {
  EyeIcon,
  BoltIcon,
  ChatBubbleLeftRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import ButtonGetWorkflow from "./../../apiGetWorkflow";

const FloatingActionButtons = ({
  setActiveTab,
  setIsDrawerOpen,
  workflowComplete,
  plotting,
}) => {
  const handleVisualizeClick = () => {
    setActiveTab("visualize");
    setIsDrawerOpen(true);
  };

  const handleBoltClick = () => {
    setActiveTab("run");
    setIsDrawerOpen(true);
  };
  const handleChatClick = () => {
    setActiveTab("chat");
    setIsDrawerOpen(true);
  };
  const handleInfoClick = () => {
    setActiveTab("info");
    setIsDrawerOpen(true);
  };

  return (
    <div className="absolute bottom-25 right-8 flex flex-col space-y-4">
      {workflowComplete && (
        <ButtonGetWorkflow className="w-14 h-14 rounded-full bg-white border border-gray-300 shadow-lg flex items-center justify-center hover:bg-[#162448] hover:text-white transition duration-300" />
      )}
      {plotting && (
        <button
          onClick={handleVisualizeClick} // Handle click for the bolt button
          className="w-14 h-14 rounded-full bg-white border border-gray-300 shadow-lg flex items-center justify-center hover:bg-[#162448] hover:text-white transition duration-300"
        >
          <EyeIcon className="h-6 w-6 text-gray-600 hover:text-white" />
        </button>
      )}
      <button
        onClick={handleBoltClick} // Handle click for the bolt button
        className="w-14 h-14 rounded-full bg-white border border-gray-300 shadow-lg flex items-center justify-center hover:bg-[#162448] hover:text-white transition duration-300"
      >
        <BoltIcon className="h-6 w-6 text-gray-600 hover:text-white" />
      </button>
      <button
        onClick={handleChatClick} // Handle click for the chat button
        className="w-14 h-14 rounded-full bg-white border border-gray-300 shadow-lg flex items-center justify-center hover:bg-[#162448] hover:text-white transition duration-300"
      >
        <ChatBubbleLeftRightIcon className="h-6 w-6 text-gray-600 hover:text-white" />
      </button>
      <button
        onClick={handleInfoClick} // Handle click for the info button
        className="w-14 h-14 rounded-full bg-white border border-gray-300 shadow-lg flex items-center justify-center hover:bg-[#162448] hover:text-white transition duration-300"
      >
        <InformationCircleIcon className="h-6 w-6 text-gray-600 hover:text-white" />
      </button>
    </div>
  );
};

export default FloatingActionButtons;
