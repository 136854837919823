export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-blue-200",
    title: "Prompt",
    action: "prompt",
    type: "rag",
    subclass: "Agentic",
    shortdescription: `
Construct a prompt for an LLM
    `,
    description: `
    Retrieve relevant information from a vector store.
    `,
    numTargets: 0,
    numSources: 1,
    targetLabels: [],
    sourceLabels: ["Prompt Message"],
    targetTypes: [],
    sourceTypes: ["dict"],
    params: {},
    dynamicList: true,
  },
};
