// randomNameGenerator.js

const adjectives = [
  "Agile",
  "Brave",
  "Calm",
  "Diligent",
  "Eager",
  "Fearless",
  "Gentle",
  "Humble",
  "Joyful",
  "Kind",
  "Lively",
  "Modest",
  "Noble",
  "Optimistic",
  "Patient",
  "Quiet",
  "Resourceful",
  "Strong",
  "Thoughtful",
  "Uplifting",
  "Vibrant",
  "Witty",
  "Zealous",
  "Bold",
  "Clever",
  "Dependable",
  "Energetic",
  "Friendly",
  "Gracious",
  "Honest",
  "Imaginative",
  "Jolly",
  "Keen",
  "Loyal",
  "Mindful",
  "Neat",
  "Observant",
  "Playful",
  "Quick",
  "Radiant",
  "Sincere",
  "Trustworthy",
  "Understanding",
  "Versatile",
  "Warm",
  "Youthful",
  "Zesty",
  "Adventurous",
  "Balanced",
  "Capable",
];

const animals = [
  "Antelope",
  "Bear",
  "Cat",
  "Dog",
  "Elephant",
  "Fox",
  "Giraffe",
  "Hawk",
  "Iguana",
  "Jaguar",
  "Kangaroo",
  "Lemur",
  "Meerkat",
  "Narwhal",
  "Owl",
  "Penguin",
  "Quokka",
  "Rabbit",
  "Squirrel",
  "Turtle",
  "Vulture",
  "Wolf",
  "Yak",
  "Zebra",
  "Aardvark",
  "Beaver",
  "Camel",
  "Dolphin",
  "Eagle",
  "Flamingo",
  "Goat",
  "Hedgehog",
  "Impala",
  "Jellyfish",
  "Koala",
  "Llama",
  "Moose",
  "Newt",
  "Octopus",
  "Porcupine",
  "Quail",
  "Raccoon",
  "Seal",
  "Tiger",
  "Urchin",
  "Viper",
  "Walrus",
  "Xerus",
  "Yak",
  "Zebra",
];

// Function to generate random name
export function generateRandomName() {
  const randomAdjective =
    adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomAnimal = animals[Math.floor(Math.random() * animals.length)];
  const randomNumber = Math.floor(100 + Math.random() * 900); // 3-digit number between 100-999

  return `${randomAdjective}-${randomAnimal}-${randomNumber}`;
}
