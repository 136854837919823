export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-pink-200",
    title: "Markdown Export",
    type: "plot",
    subclass: "export",
    shortdescription: `
Generate an markdown report from agentic output.
    `,
    description: `
This allows you to add a twinLab Model you have build, validated and registered within a twinLab workflow.
    `,
    numTargets: 1,
    numSources: 0,
    targetLabels: ["Agent Output"],
    sourceLabels: [],
    targetTypes: ["dict"],
    sourceTypes: [],
    params: {},
    action: "markdownExport",
  },
};
