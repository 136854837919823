export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-blue-200",
    title: "Linspace",
    type: "math",
    subclass: "Mathematical Tool",
    action: "linspace",
    shortdescription: `
  Generate evenly spaced values over a specified interval.
      `,
    description: `
  Given a start value, an end value, and the number of points, generate an array of evenly spaced values over that interval.
      `,
    numTargets: 3,
    numSources: 1,
    targetLabels: ["Start Value", "End Value", "Number of Points"],
    sourceLabels: ["Generated Values"],
    targetTypes: ["number", "number", "number"],
    sourceTypes: ["samples"],
    params: {},
  },
};
