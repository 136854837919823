import { ResponsiveRadar } from "@nivo/radar";

const RadarPlot = ({ data }) => (
  <ResponsiveRadar
    data={data}
    keys={["myModel"]}
    indexBy="taste"
    valueFormat=">-.2f"
    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
    borderColor={{ from: "color" }}
    gridLabelOffset={20}
    dotSize={10}
    dotColor="#FFFFFF"
    dotBorderWidth={2}
    dotBorderColor="#0D9488"
    colors={["#0D9488"]}
    blendMode="multiply"
    motionConfig="wobbly"
    legends={[]}
  />
);

export default RadarPlot;
