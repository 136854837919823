import {
  AccountInfo,
  ProjectInfo,
  OrganizationInfo,
  WorkflowInfo,
  WorkflowHist,
  ModelInfo,
  CognitoInfo,
  AppInfo,
} from "./interfaces";

// MOCK USER DATA

export const accounts: AccountInfo[] = [
  {
    id: "u1",
    name: "Andrew Corbett",
    title: "Head of Research & Development",
    department: "R&D",
    email: "andy@diglab.co.uk",
    status: "Active",
    created_at: new Date("2024-08-15T10:02:02Z"),
  },
  {
    id: "u2",
    name: "Ross Allen",
    title: "Senior Solutions Engineer",
    department: "BD",
    email: "ross@digilab.co.uk",
    status: "Active",
    created_at: new Date("2024-08-15T10:02:02Z"),
  },
  {
    id: "u3",
    name: "Tim Dodwell",
    title: "Junior React Developer",
    department: "Development",
    email: "tim@digilab.co.uk",
    status: "Active",
    created_at: new Date("2024-08-15T10:02:02Z"),
  },
  {
    id: "u4",
    name: "Anhad Sandu",
    email: "anhad@digilab.co.uk",
    title: "Co-Founder & GM Saudi",
    department: "Management",
    status: "Active",
    created_at: new Date("2024-08-15T10:02:02Z"),
  },
  {
    id: "u5",
    name: "Amanda Niedfeldt",
    email: "amanda@digilab.co.uk",
    title: "Head of Business Development",
    department: "BD",
    status: "Active",
    created_at: new Date("2024-08-15T10:02:02Z"),
  },
  {
    id: "u6",
    name: "Alan Prior",
    email: "alan@digilab.co.uk",
    title: "COO",
    department: "Management",
    status: "Active",
    created_at: new Date("2024-08-15T10:02:02Z"),
  },
  // Add more users as needed
];

export const cognito: CognitoInfo[] = [
  {
    id: "u1",
    imageUrl: "https://www.digilab.co.uk/images/team/andy_corbett.png",
  },
  {
    id: "u2",
    imageUrl: "https://www.digilab.co.uk/images/team/ross_allen.jpg",
  },
  {
    id: "u3",
    imageUrl: "https://www.digilab.co.uk/images/team/tim_dodwell.jpg",
  },
  {
    id: "u4",
    imageUrl: "https://www.digilab.co.uk/images/team/anhad_sandhu.jpg",
  },
  {
    id: "u5",
    imageUrl: "https://www.digilab.co.uk/images/team/amanda_niedfeldt.jpg",
  },
  {
    id: "u6",
    imageUrl: "https://www.digilab.co.uk/images/team/alan_prior.jpg",
  },
  // Add more users as needed
];

// MOCK PROJECT DATA

export const projects: ProjectInfo[] = [
  {
    id: "p1",
    owner_id: "u3",
    organization_id: "1",
    members: {
      u1: "Admin",
      u2: "Admin",
      u3: "Owner",
    },
    name: "FullGyroX",
    created_at: new Date("2024-08-21T13:17:22Z"),
    updated_at: new Date("2021-09-18T10:46:01Z"),
  },
  {
    id: "p2",
    owner_id: "u4",
    organization_id: "1",
    members: {
      u4: "Owner",
      u5: "Admin",
      u1: "Admin",
    },
    name: "Saudi project",
    created_at: new Date("2024-08-15T10:02:02Z"),
    updated_at: new Date("2024-08-15T11:34:57Z"),
  },
  // Add more projects as needed
];

// MOCK ORGANIZATION DATA

export const organizations: OrganizationInfo[] = [
  {
    id: "o1",
    organization_name: "digiLab",
    organization_data: {
      location: "UK",
      industry: "Research & Development",
    },
    members: {
      u1: "Member",
      u2: "Member",
      u3: "Owner",
      u4: "Admin",
      u5: "Member",
      u6: "Admin",
    },
    created_at: new Date("2024-08-15T10:02:02Z"),
    updated_at: new Date("2024-08-15T11:34:57Z"),
  },
  // Add more organizations as needed
];

// MOCK WORKFLOW DATA

export const workflows: WorkflowInfo[] = [
  {
    id: "w1",
    project_id: "p1",
    owner_id: "u3",
    versions: ["v1", "v2", "v3", "v4"],
    name: "FullGyroX.flw",
    description:
      "This work flow does some sampling, puts it through a twinLab model and then plots it.",
    nodes: ["n1", "n2", "n3"],
    created_at: new Date("2024-08-21T13:17:22Z"),
    is_locked: false,
  },
  // Add more workflows as needed
];

// MOCK WORKFLOW HISTORY DATA

export const workflowVersions: WorkflowHist[] = [
  {
    id: "w1",
    version: "v1",
    user: "u2",
    marked_as: "draft",
    action: "Workflow created",
    timestamp: new Date("2020-09-20T13:17:22Z"),
  },
  {
    id: "w1",
    version: "v2",
    user: "u3",
    marked_as: "draft",
    action: "Workflow edited",
    timestamp: new Date("2020-09-22T14:20:22Z"),
  },
  {
    id: "w1",
    version: "v3",
    user: "u2",
    marked_as: "completed",
    action: "Workflow marked as completed",
    timestamp: new Date("2020-09-28T08:46:38Z"),
  },
  {
    id: "w1",
    version: "v4",
    user: "u1",
    marked_as: "completed",
    action: "Workflow edited and saved",
    timestamp: new Date("2020-09-30T12:07:03Z"),
  },
];

// MOCK MODEL DATA

export const models: ModelInfo[] = [
  {
    id: "m1",
    project_id: "p1",
    owner_id: "u3",
    versions: ["v1"],
    name: "tokamak.mod",
    created_at: new Date("2024-09-15T10:00:01Z"),
    is_locked: false,
  },
];

// MOCK APP DATA

export const apps: AppInfo[] = [
  {
    id: "a1",
    workflow_id: "w1",
    project_id: "p1",
    owner_id: "u3",
    versions: ["v1"],
    name: "AppChatFullGyroX.app",
    created_at: new Date("2024-09-15T10:00:01Z"),
    is_locked: false,
  },
];
