export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-teal-200",
    title: "Summary Agent",
    action: "summaryAgent",
    type: "rag",
    subclass: "rag",
    shortdescription: `
Summary Agent
    `,
    description: `
    Summary Agent
    `,
    numTargets: 2,
    numSources: 1,
    targetLabels: ["Prompt", "Relevant Info."],
    sourceLabels: ["Answer"],
    targetTypes: ["str", "dict"],
    sourceTypes: ["dict"],
    params: {},
  },
};
