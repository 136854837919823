import React, { memo, useState, useEffect } from "react";
import { Handle, Position } from "@xyflow/react";
import {
  InformationCircleIcon,
  ChevronUpIcon,
  PencilSquareIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import MarkdownRenderer from "../../utilities/MarkdownRenderer";
import generateHandles from "./NodeComponents/generateHandles";
import NodeDropdownMenu from "./NodeComponents/NodeDropdownMenu";
import DynamicList from "./NodeComponents/DynamicList";

const statusColorMap = {
  green: "bg-green-400",
  amber: "bg-yellow-400",
  red: "bg-red-400",
};

function BaseNode({ data }) {
  const {
    unitWidth,
    title,
    headerColor,
    description,
    onEdit,
    setNodes,
    id,
    numTargets = 0,
    numSources = 0,
    targetLabels = [],
    sourceLabels = [],
    targetTypes = [],
    sourceTypes = [],
    status = "amber",
    dynamicList = false,
    listExtraInputs = [],
  } = data;

  const headerHeight = 60;
  const {
    handles: staticHandles,
    offsetTarget,
    offsetSource,
  } = generateHandles(
    numTargets,
    numSources,
    targetTypes,
    sourceTypes,
    headerHeight
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [extraInputs, setextraInputs] = useState(listExtraInputs);
  const [dynamicHandles, setDynamicHandles] = useState([]);

  const toggleExpansion = () => setIsExpanded(!isExpanded);

  // Sync dynamicHandles with node.data.handles
  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                handles: [...(staticHandles || []), ...(dynamicHandles || [])], // Merge static and dynamic handles
              },
            }
          : node
      )
    );
  }, [dynamicHandles, staticHandles, id, setNodes]);

  // Add dynamic handles based on extraInputs
  const addDynamicHandle = () => {
    const newHandle = {
      id: `extra_handle_${extraInputs.length + 1}`,
      type: "target",
      position: Position.Left,
      style: {
        top: 60 + 60 + 30 + 60 * extraInputs.length,
        position: "absolute",
      },
      dataType: "str",
    };
    setDynamicHandles((prevHandles) => [...prevHandles, newHandle]);
  };

  const deleteDynamicHandle = (handleId) => {
    setDynamicHandles((handles) =>
      handles.filter((handle) => handle.id !== handleId)
    );
  };

  return (
    <div className="rounded-lg border border-gray-400">
      <div
        className="relative border-b border-gray-900/5 bg-gray-50 p-0 rounded-lg"
        style={{ width: unitWidth }}
      >
        <span
          className={`absolute -top-1 -right-1 block h-4 w-4 rounded-full ring-2 ring-white ${statusColorMap[status]}`}
        />

        <div
          className={`flex items-center justify-between ${headerColor} py-2 px-4 rounded-t-lg`}
          style={{ width: unitWidth, height: "60px" }}
        >
          <div className="text-lg font-bold leading-6 text-gray-900">
            {title}
          </div>

          <NodeDropdownMenu
            menuItems={[
              {
                label: "Edit / View Parameters",
                icon: PencilSquareIcon,
                onClick: onEdit,
              },
              {
                label: "Duplicate",
                icon: DocumentDuplicateIcon,
                onClick: () => console.log("Duplicate clicked"),
              },
              {
                label: "Delete",
                icon: TrashIcon,
                onClick: () => console.log("Delete clicked"),
              },
            ]}
          />
        </div>

        <div
          className="flex flex-row items-start p-4 bg-gray-175 relative rounded-b-lg"
          style={{ width: unitWidth }}
        >
          {/* Left Section */}
          <div className="flex-1 flex flex-col">
            {Array.from({ length: numTargets }).map((_, index) => (
              <div
                key={`target-${index}`}
                className="flex-1 text-sm text-gray-600"
                style={{
                  position: "absolute",
                  top: (index + 1) * offsetTarget + "px",
                  left: "10px",
                  transform: "translateY(-55%)",
                }}
              >
                {targetLabels[index] || `Target ${index + 1}`}
              </div>
            ))}
          </div>

          {/* Right Section */}
          <div
            className="flex-1 flex flex-col text-right justify-center"
            style={{
              height: 30 * (Math.max(numTargets, numSources) + 1) + "px",
            }}
          >
            {Array.from({ length: numSources }).map((_, index) => (
              <div
                key={`source-${index}`}
                className="flex-1 text-sm text-gray-600"
                style={{
                  position: "absolute",
                  top: (index + 1) * offsetSource + "px",
                  right: "10px",
                  transform: "translateY(-55%)",
                }}
              >
                {sourceLabels[index] || `Source ${index + 1}`}
              </div>
            ))}
          </div>

          {/* Toggle Button */}
          <button
            type="button"
            onClick={toggleExpansion}
            className="absolute bottom-1 right-1 text-gray-500 hover:text-gray-800"
          >
            {isExpanded ? (
              <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
        </div>

        {isExpanded && (
          <div
            className="divide-y divide-gray-100 px-6 py-4 text-sm text-gray- leading-6 bg-yellow-50 rounded-lg border-gray-400"
            style={{ width: unitWidth }}
          >
            <MarkdownRenderer markdownString={description} />
          </div>
        )}
      </div>

      {dynamicList && (
        <div
          className="flex flex-col divide-y divide-gray-100 px-2 text-sm text-gray- leading-6 bg-gray-50 rounded-b-lg border-gray-400"
          style={{ width: unitWidth }}
        >
          <DynamicList
            headerHeight={headerHeight}
            extraInputs={extraInputs}
            setextraInputs={setextraInputs}
            addDynamicHandle={addDynamicHandle} // Call this when extraInputs changes
          />
        </div>
      )}

      {/* Render Static Handles */}
      {staticHandles.map((handleConfig) => (
        <Handle
          key={handleConfig.id}
          id={handleConfig.id}
          type={handleConfig.type}
          position={handleConfig.position}
          className="rounded-full w-2 h-2 !bg-black ring-2 ring-gray-00 ring-offset-2 ring-offset-gray-50"
          style={handleConfig.style}
        />
      ))}

      {/* Render Dynamic Handles */}
      {dynamicHandles.map((handle, i) => (
        <Handle
          key={handle.id}
          id={handle.id}
          type={handle.type}
          position={handle.position}
          className="rounded-full w-2 h-2 !bg-black ring-2 ring-gray-00 ring-offset-2 ring-offset-gray-50"
          style={{
            top: 340 + 58 * i,
            position: "absolute",
          }}
        />
      ))}
    </div>
  );
}

export default memo(BaseNode);
