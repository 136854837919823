const HeaderModelBuilder = ({ headerText, subHeading = "" }) => {
  return (
    <div className="w-full h-16 flex flex-col justify-center bg-teal-50 border border-gray-300 rounded-t-lg px-3">
      <p className="font-semibold text-lg">{headerText}</p>
      <p className="text-sm text-gray-400">{subHeading}</p>
    </div>
  );
};

export default HeaderModelBuilder;
