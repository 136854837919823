export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-blue-200",
    title: "Gaussian Likelihood",
    action: "likelihood",
    type: "math",
    subclass: "Statistical Methods",
    shortdescription: `
Compare a model output against data.
    `,
    description: `
The **Gaussian Likelihood** is a measure of how well a statistical model predicts observed data under the assumption that the errors (differences between model predictions and actual data) can be modelled with a Gaussian Distribution.

The **inputs** need to be model predictions of your data and your data. The **output** will be a single value of the (log) likelihood, for each prediction which is provided.

**Parameters** include the variance of the noise \( \sigma^2 \) and also an option if to return the log-likelihood, rather than the likelihood.
    `,
    numTargets: 4,
    numSources: 1,
    targetLabels: ["Model Output", "Data", "Sigma", "Return Log?"],
    sourceLabels: ["Likelihood"],
    targetTypes: ["samples", "samples", "float", "boolean"],
    sourceTypes: ["samples"],
  },
};
