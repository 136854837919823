import { CheckIcon } from "@heroicons/react/20/solid";

const steps = [
  { name: "Model Info.", href: "#", status: "complete" },
  { name: "Dataset", href: "#", status: "complete" },
  { name: "ML Model", href: "#", status: "current" },
  { name: "Training", href: "#", status: "upcoming" },
  { name: "Validate", href: "#", status: "upcoming" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProgressBarNew() {
  return (
    <nav aria-label="Progress" className="mt-4 items-center mb-4">
      <ol role="list" className="flex items-center">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
              "relative flex flex-col items-center"
            )}
          >
            {step.status === "complete" ? (
              <>
                <div
                  aria-hidden="true"
                  className="absolute inset-0 translate-x-1/4 top-1/3 -translate-y-2/3 flex items-center"
                >
                  <div className="h-0.5 w-full bg-teal-600" />
                </div>
                <a
                  href={step.href}
                  className="relative flex h-8 w-8 items-center justify-center rounded-full bg-teal-600 hover:bg-teal-900"
                >
                  <CheckIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-white"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : step.status === "current" ? (
              <>
                <a
                  href={step.href}
                  aria-current="step"
                  className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-teal-600 bg-white"
                >
                  <span
                    aria-hidden="true"
                    className="h-2.5 w-2.5 rounded-full bg-teal-600"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : (
              <>
                <a
                  href={step.href}
                  className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                >
                  <span
                    aria-hidden="true"
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            )}

            {/* Step Name below the dot, centered */}
            <span className="block text-sm text-center mt-2 text-gray-600">
              {step.name}
            </span>
          </li>
        ))}
      </ol>
    </nav>
  );
}
