export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-orange-200",
    title: "Predict",
    subclass: "Outputs",
    type: "output",
    action: "predict",
    shortdescription: `
      Make predictions using a trained model.
          `,
    description: `
    This fucntion takes a trained model and a set of input data and makes predictions using the model.
      `,
    numTargets: 2,
    numSources: 1,
    targetLabels: ["Model", "Input Data"],
    sourceLabels: ["Predictions"],
    targetTypes: ["tL_Model", "samples"],
    sourceTypes: ["samples"],
    params: {},
  },
};
