import React, { useState } from "react";
import {
  PlusIcon,
  ChevronDownIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/outline";
import { getProjectMembers, getUsersDetails } from "../../utilities/dbUtils";

export default function RightToolbar({ setActiveModal, currentProject }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown state

  const projectMembers = getProjectMembers(currentProject);
  const currentTeam = getUsersDetails(projectMembers, currentProject);

  return (
    <div className="flex items-center p-2 bg-white border border-gray-400 rounded-md h-14 space-x-4">
      {/* Zoom Controls */}
      <div className="flex items-center space-x-2">
        <button
          className="flex items-center justify-center w-10 h-10 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          aria-label="Zoom Out"
        >
          <ArrowsPointingOutIcon
            className="h-5 w-5 text-gray-600"
            aria-hidden="true"
          />
        </button>
        <div className="relative">
          <select
            className="block appearance-none w-28 h-10 bg-white border border-gray-300 text-gray-700 py-2 pl-3 pr-8 rounded-md leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
            aria-label="Zoom Level"
          >
            <option>100%</option>
            <option>75%</option>
            <option>50%</option>
            <option>25%</option>
          </select>
        </div>
      </div>

      {/* Team Members */}
      <div className="flex items-center space-x-2">
        <div className="flex -space-x-1 overflow-hidden">
          {currentTeam.slice(0, 3).map((person) => (
            <a key={person.email} href="#" className="relative">
              <img
                src={person.imageUrl}
                alt={person.name}
                className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
              />
            </a>
          ))}
          {currentTeam.length > 3 && (
            <span className="flex items-center justify-center h-8 w-8 rounded-full bg-gray-200 text-gray-700 text-xs font-medium ring-2 ring-white">
              +{currentTeam.length - 3}
            </span>
          )}
        </div>
        <button
          type="button"
          onClick={() => setActiveModal("ManageProjectSharingModal")}
          className="flex items-center justify-center h-8 w-8 rounded-full bg-white border border-dashed border-gray-300 text-gray-400 hover:border-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          aria-label="Add team member"
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>

      {/* Spacer */}
      <div className="flex-grow"></div>

      {/* Actions Dropdown */}
      <div className="relative inline-block text-left">
        <button
          type="button"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          id="actions-menu"
          aria-expanded="true"
          aria-haspopup="true"
        >
          Actions
          <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
        </button>

        {/* Dropdown menu */}
        {isDropdownOpen && (
          <div
            className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="actions-menu"
          >
            <div className="py-1">
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                Save
              </a>
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                Load
              </a>
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                Copy
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
