import React, { useState } from "react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { generateRandomName } from "./randomNameGenerator";

const statuses = {
  offline: "text-amber-300 bg-amber-50",
  online: "text-teal-300 bg-teal-50",
  error: "text-rose-300 bg-rose-50",
};

const versions = [
  {
    id: 1,
    href: "#",
    modelName: "modelName",
    versionName: generateRandomName(),
    status: "offline",
    statusText: "1m 32s ago",
    description: "timdodwell",
    score: 85, // Percentage score
  },
  {
    id: 2,
    href: "#",
    modelName: "modelName",
    versionName: generateRandomName(),
    status: "online",
    statusText: "Deployed 3m ago",
    description: "rossallen",
    score: 95, // Percentage score
  },
  {
    id: 3,
    href: "#",
    modelName: "modelName",
    versionName: generateRandomName(),
    status: "offline",
    statusText: "Deployed 3h ago",
    description: "fredword",
    score: 75, // Percentage score
  },
  {
    id: 4,
    href: "#",
    modelName: "modelName",
    versionName: generateRandomName(),
    status: "error",
    statusText: "Failed to deploy 6d ago",
    description: "timdodwell",
    score: 65, // Percentage score
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function VersionsList({ itemHeight = "auto" }) {
  const [deployments, setDeployments] = useState(versions);
  const [isSortedByScore, setIsSortedByScore] = useState(false);

  const handleSortByScore = () => {
    const sortedDeployments = [...deployments].sort((a, b) =>
      isSortedByScore ? a.score - b.score : b.score - a.score
    );
    setDeployments(sortedDeployments);
    setIsSortedByScore(!isSortedByScore);
  };

  return (
    <div>
      <div className="flex items-center justify-between pl-4">
        <h3 className="text-sm font-semibold text-gray-900"></h3>
        <button
          onClick={handleSortByScore}
          className="flex items-center text-xs font-medium text-gray-600 hover:text-gray-900"
        >
          Sort
          <ChevronDownIcon
            className="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </button>
      </div>
      <ul role="list" className="divide-y divide-gray-200">
        {deployments.map((deployment) => (
          <li
            key={deployment.id}
            className="relative flex items-center space-x-4"
            style={{ height: itemHeight }}
          >
            <div className="min-w-0 flex-auto">
              <div className="flex items-center gap-x-3">
                <div
                  className={classNames(
                    statuses[deployment.status],
                    "flex-none rounded-full p-1"
                  )}
                >
                  <div className="h-2 w-2 rounded-full bg-current" />
                </div>
                <h2 className="min-w-0 text-sm font-semibold leading-6 text-gray-900">
                  <a href={deployment.href} className="flex gap-x-2">
                    <span className="truncate">{deployment.versionName}</span>
                    <span className="text-gray-400">/</span>
                    <span className="whitespace-nowrap">
                      {deployment.modelName}
                    </span>
                    <span className="absolute inset-0" />
                  </a>
                </h2>
              </div>
              <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-500">
                <p className="truncate">{deployment.description}</p>
                <svg
                  viewBox="0 0 2 2"
                  className="h-0.5 w-0.5 flex-none fill-gray-300"
                >
                  <circle r={1} cx={1} cy={1} />
                </svg>
                <p className="whitespace-nowrap">{deployment.statusText}</p>
              </div>
            </div>
            <div className="flex-none text-sm font-medium text-gray-900">
              {deployment.score}%
            </div>
            <ChevronRightIcon
              aria-hidden="true"
              className="h-5 w-5 flex-none text-gray-400"
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
