import React, { useEffect, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
  getProjectMembers,
  getUsersDetails,
  getAccountImage,
  getAccountName,
  getTabName,
} from "../../utilities/dbUtils";

import Timeline from "./TimeLine";

import { saveAs } from "file-saver"; // Import the FileSaver library
import PlotPanel from "../../plot/PlotPanel.tsx";

const workflow_description =
  "This work flow does some sampling, puts it through a twinLab model and then plots it.";

const run_context =
  "With this run I am trying to see what happens if I change the input distribution.";

const conversationHistory = [
  { sender: "ChatGPT", text: "Hello! How can I assist you today?" },
  {
    sender: "User",
    text: "Can you help me understand how to use the workflow canvas?",
  },
  {
    sender: "ChatGPT",
    text: "Certainly! The workflow canvas allows you to visually design and manage your workflows. You can add nodes, connect them, and configure each node based on your needs.",
  },
  { sender: "User", text: "That sounds great, thank you!" },
];

export default function RightDrawer({
  open,
  setOpen,
  activeTab,
  setActiveTab,
  displayInfo,
  currentUser,
  currentProject,
  currentTabId,
}) {
  const [width, setWidth] = useState(600); // Initial width of the drawer

  // Example state variables that you'll use for saving
  const [description, setDescription] = useState(workflow_description);
  const [context, setContext] = useState(run_context);
  const [workflowName, setWorkflowName] = useState("");

  const [enableGPUs, setEnableGPUs] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [timeout, setTimeout] = useState(false);

  const projectMembers = getProjectMembers(currentProject);
  const currentTeam = getUsersDetails(projectMembers, currentProject);
  const workflow_name = getTabName(currentTabId);

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startWidth = width;

    const handleMouseMove = (e) => {
      const newWidth = startWidth - (e.clientX - startX);
      if (newWidth >= 300 && newWidth <= window.innerWidth - 100) {
        setWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const [chatMessages, setChatMessages] = useState(conversationHistory);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = (e) => {
    e.preventDefault(); // Prevent form submission
    if (newMessage.trim() !== "") {
      setChatMessages([...chatMessages, { sender: "User", text: newMessage }]);
      setNewMessage(""); // Clear the input field
    }
  };

  // Function to handle save
  const handleSave = () => {
    const dataToSave = {
      workflowName,
      description,
      context,
      nodes: displayInfo.nodes, // Assuming you pass displayInfo as a prop to RightDrawer
      edges: displayInfo.edges,
      options: {
        enableGPUs,
        emailNotification,
        timeout,
      },
    };

    const blob = new Blob([JSON.stringify(dataToSave, null, 2)], {
      type: "application/json",
    });
    saveAs(blob, `${workflowName || "workflow"}.json`);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="fixed inset-0 z-50"
    >
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex pl-10 sm:pl-16">
            <DialogPanel
              transition
              style={{ width: width }} // Apply the dynamic width
              className="width pointer-events-auto h-full transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700 bg-white shadow-xl"
            >
              <form className="flex h-full flex-col divide-y divide-gray-200">
                <div className="h-0 flex-1 overflow-y-auto">
                  {/* Top Bar */}
                  <div className="bg-gray-100 px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-black">
                        {workflow_name}
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-gray-100 text-gray-500 hover:text-black focus:outline-none focus:ring-2 focus:ring-gray-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="px-4 sm:px-6">
                    <div className="flex space-x-4">
                      <button
                        type="button"
                        className={`py-2 px-4 font-semibold text-sm ${
                          activeTab === "info"
                            ? "border-b-2 border-gray-600 text-black"
                            : "text-gray-500"
                        }`}
                        onClick={() => setActiveTab("info")}
                      >
                        Info
                      </button>
                      <button
                        type="button"
                        className={`py-2 px-4 font-semibold text-sm ${
                          activeTab === "run"
                            ? "border-b-2 border-gray-600 text-black"
                            : "text-gray-500"
                        }`}
                        onClick={() => setActiveTab("run")}
                      >
                        Run
                      </button>
                      <button
                        type="button"
                        className={`py-2 px-4 font-semibold text-sm ${
                          activeTab === "chat"
                            ? "border-b-2 border-gray-600 text-black"
                            : "text-gray-500"
                        }`}
                        onClick={() => setActiveTab("chat")}
                      >
                        Chat
                      </button>
                      <button
                        type="button"
                        className={`py-2 px-4 font-semibold text-sm ${
                          activeTab === "visualize"
                            ? "border-b-2 border-gray-600 text-black"
                            : "text-gray-500"
                        }`}
                        onClick={() => setActiveTab("visualize")}
                      >
                        Visualize
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-1 flex-col justify-between px-4 sm:px-6 py-4">
                    {/* This is the start of the Info Tab */}
                    {activeTab === "info" && (
                      <div className="space-y-6 relative">
                        <div className="relative">
                          <label
                            htmlFor="description"
                            className="block text-sm font-large leading-6 text-gray-900"
                          >
                            <b>Description</b>
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="description"
                              name="description"
                              rows={4}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              defaultValue={workflow_description}
                            />
                          </div>
                          <div className="mt-2 flex justify-end">
                            <button
                              type="button"
                              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                              onClick={() => alert("Saved!")} // Replace with your save handler
                            >
                              Save
                            </button>
                          </div>
                        </div>

                        <div className="block text-sm font-large leading-6 text-gray-900">
                          <b>Team Sharing Workflow</b>
                        </div>

                        <div className="flex overflow-hidden ml-2 h-12">
                          {currentTeam.slice(0, 5).map((person) => (
                            <a
                              key={person.email}
                              href="#"
                              className="relative rounded-full hover:opacity-75"
                            >
                              <img
                                alt={person.name}
                                src={person.imageUrl}
                                className="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                              />
                            </a>
                          ))}

                          {currentTeam.length > 5 && (
                            <div className="relative top-1 inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-blue-800 text-white text-xs ring-2 ring-blue">
                              +{currentTeam.length - 3}
                            </div>
                          )}

                          <button
                            type="button"
                            className="mr-2 relative top-2 inline-flex h-8 w-8 left-2 flex-shrink-0 items-center justify-center rounded-full border-2 border-dashed border-gray-300 bg-white text-gray-400 hover:border-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            <span className="sr-only">Add team member</span>
                            <PlusIcon aria-hidden="true" className="h-5 w-5" />
                          </button>
                        </div>

                        <div>
                          <div className="block text-sm font-large leading-6 text-gray-900 pb-4">
                            <b>Recent Activity</b>
                          </div>
                          <Timeline currentTabId={currentTabId} />
                        </div>
                      </div>
                    )}

                    {/* This is the start of the Run Tab */}
                    {activeTab === "run" && (
                      <div>
                        <div className="relative">
                          <label
                            htmlFor="description"
                            className="block text-sm font-large leading-6 text-gray-900"
                          >
                            <b>Why are you running this particular workflow?</b>{" "}
                            By giving the twinLab context of why you are doing
                            the run our AI system, will help make connections.
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="description"
                              name="description"
                              rows={4}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              defaultValue={run_context}
                            />
                          </div>
                          <div className="mt-2 flex justify-end">
                            <button
                              type="button"
                              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                              onClick={() => alert("Context saved!")} // Replace with your save handler
                            >
                              Save
                            </button>
                          </div>
                        </div>

                        <div className="mt-4">
                          <label
                            htmlFor="workflowName"
                            className="block text-sm font-large leading-6 text-gray-900"
                          >
                            <b>Workflow Name</b>
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              id="workflowName"
                              name="workflowName"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              placeholder="Enter workflow name"
                            />
                          </div>
                        </div>

                        <div className="block text-sm font-large leading-6 text-gray-900 pb-4 mt-4">
                          <b>Compute Options</b>
                        </div>

                        <fieldset>
                          <legend className="sr-only">Run Options</legend>
                          <div className="space-y-5">
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="enableGPUs"
                                  name="enableGPUs"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="enableGPUs"
                                  className="font-medium text-gray-900"
                                >
                                  Enable GPUs
                                </label>
                                <p className="text-gray-500">
                                  By setting this option you will utilise GPU to
                                  accelerate compute times of workflows.
                                </p>
                              </div>
                            </div>

                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="emailNotification"
                                  name="emailNotification"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="emailNotification"
                                  className="font-medium text-gray-900"
                                >
                                  Email Notification
                                </label>
                                <p className="text-gray-500">
                                  By enabling this option, you will receive an
                                  email once the workflow computation has been
                                  completed, or a user-input is required.
                                </p>
                              </div>
                            </div>

                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="timeout"
                                  name="timeout"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="timeout"
                                  className="font-medium text-gray-900"
                                >
                                  Timeout [1 hr]
                                </label>
                                <p className="text-gray-500">
                                  Setting this option, will be put a timeout on
                                  your analysis return the outputs it has after
                                  a given period of time.
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        <div className="mt-4 flex justify-end">
                          <button
                            type="button"
                            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                            onClick={handleSave}
                          >
                            Submit & Save
                          </button>
                        </div>
                      </div>
                    )}

                    {/* This is the start of the Chat Tab */}
                    {activeTab === "chat" && (
                      <div className="relative h-full flex flex-col">
                        <h3 className="text-sm font-medium leading-6 text-gray-900">
                          Chat
                        </h3>
                        <div className="flex-grow mt-3 text-gray-600 text-sm space-y-4 overflow-y-auto">
                          {chatMessages.map((message, index) => (
                            <div
                              key={index}
                              className={`flex ${message.sender === "ChatGPT" ? "justify-start" : "justify-end"} items-end`}
                            >
                              {message.sender === "ChatGPT" ? (
                                <div className="flex items-end">
                                  <div className="w-8 h-8 rounded-full bg-[#162448] flex items-center justify-center text-white mr-2">
                                    tL
                                  </div>
                                  <div className="rounded-lg px-4 py-2 max-w-xs bg-gray-200 text-gray-800">
                                    <p className="text-sm">{message.text}</p>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex items-end">
                                  <div className="rounded-lg px-4 py-2 max-w-xs bg-blue-500 text-white">
                                    <p className="text-sm">{message.text}</p>
                                  </div>
                                  <img
                                    src={getAccountImage(currentUser)}
                                    alt={getAccountName(currentUser)}
                                    className="w-8 h-8 rounded-full ml-2"
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="mt-4 flex-none">
                          <div className="flex">
                            <input
                              type="text"
                              value={newMessage}
                              onChange={(e) => setNewMessage(e.target.value)}
                              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-600"
                              placeholder="Type your message..."
                            />
                            <button
                              type="button"
                              onClick={handleSendMessage}
                              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* This is the start of the Chat Tab */}
                    {activeTab === "visualize" && (
                      <div className="relative h-full flex flex-col py-2">
                        <PlotPanel width={width} currentTabId={currentTabId} />
                      </div>
                    )}
                  </div>
                </div>
              </form>
              <div
                onMouseDown={handleMouseDown}
                className="absolute top-0 left-0 h-full w-2 cursor-ew-resize bg-gray-300"
                style={{ transform: "translateX(-2px)" }}
              ></div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
