export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-blue-200",
    title: "Linear Interpolation",
    type: "math",
    subclass: "Mathematical Tool",
    action: "interpolation",
    shortdescription: `
Return predictions for outputs at given inputs based on linear interpolation.
    `,
    description: `
Taking example values of input and output values, return predictions for outputs at given inputs based on linear interpolation.
    `,
    numTargets: 3,
    numSources: 1,
    targetLabels: ["Input Examples", "Output Examples", "Inputs to Evaluate"],
    sourceLabels: ["Interpolated Outputs"],
    targetTypes: ["grid", "samples", "samples"],
    sourceTypes: ["samples"],
    params: {},
  },
};
