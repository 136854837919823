export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-pink-200",
    title: "CSV Downloader",
    subclass: "Export / Visualisation",
    type: "plot",
    action: "csvDownloader",
    shortdescription: `
      Download a generated CSV file.
          `,
    description: `
  This function allows you to take an output and download it as a CSV file.
      `,
    numTargets: 1,
    numSources: 0,
    targetLabels: ["Data"],
    sourceLabels: [],
    targetTypes: ["samples"],
    sourceTypes: [],
    params: {
      headers: true,
    },
  },
};
