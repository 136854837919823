import React from "react";
import { Handle, Position } from "@xyflow/react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid"; // XMarkIcon for delete button

export default function DynamicList({
  extraInputs,
  setextraInputs,
  addDynamicHandle, // Passed from parent to add handles
}) {
  // Function to add a new item with a unique ID
  const addItem = () => {
    const newItem = { id: `dynamic_${extraInputs.length + 1}`, content: "" };
    setextraInputs([...extraInputs, newItem]);
    console.log("Here");
    // Call the parent method to add a dynamic handle
    addDynamicHandle();
  };

  // Function to delete an item by its ID
  const deleteItem = (id) => {
    setextraInputs(extraInputs.filter((item) => item.id !== id));
  };

  // Function to check if the content contains curly brackets
  const isValidVariable = (text) => {
    const regex = /\{(.*?)\}/g;
    return regex.test(text); // Returns true if curly brackets are present
  };

  const handleInputChange = (e, id) => {
    const value = e.target.value;
    setextraInputs(
      extraInputs.map((input) =>
        input.id === id ? { ...input, content: value } : input
      )
    );
  };

  return (
    <div className="w-full">
      <ul role="list" className="space-y-1">
        {/* Display extraInputs list with delete button */}

        {/* Add prompt template input */}
        <li className="overflow-hidden rounded-md bg-gray-50 py-2">
          <div className="items-start space-x-4">
            <div className="min-w-0">
              <form action="#" className="relative">
                <div className="overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                  <label htmlFor="comment" className="sr-only">
                    Add your comment
                  </label>
                  <textarea
                    id="comment"
                    name="comment"
                    rows={3}
                    placeholder="Add your prompt template..."
                    className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />

                  {/* Spacer element to match the height of the toolbar */}
                  <div aria-hidden="true" className="py-2">
                    <div className="py-px">
                      <div className="h-9" />
                    </div>
                  </div>
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between border-gray-100 py-2 pl-2 pr-2">
                  <div className="flex items-center space-x-5"></div>
                  <div className="flex-shrink-0">
                    <button
                      type="submit"
                      className="inline-flex items-center rounded-md bg-gray-100 border-gray-300 border-2 px-1 py-1 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-200"
                    >
                      <PlusIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </li>
        {extraInputs.length > 0 && (
          <ul>
            {extraInputs.map((item, i) => (
              <li
                key={item.id}
                className="overflow-hidden rounded-md bg-gray-100 px-2 py-2 shadow relative"
              >
                <div className="flex items-center">
                  {/* Textarea for dynamic input */}
                  <input
                    type="text"
                    value={item.content}
                    onChange={(e) => handleInputChange(e, item.id)}
                    placeholder={`Enter Variable`}
                    className={`w-full border border-gray-300 rounded-md p-2 ${
                      isValidVariable(item.content)
                        ? "bg-green-50 text-green-700 ring-green-600/20"
                        : "bg-yellow-50 text-yellow-800 ring-yellow-600/20"
                    }`}
                  />

                  {/* Delete button in the middle right */}
                  <button
                    type="button"
                    className="ml-2 flex items-center justify-center h-6 w-6 rounded-full text-white bg-gray-500 hover:bg-red-500 focus:outline-none"
                    onClick={() => deleteItem(item.id)}
                  >
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}

        {/* Button to add a new list item */}
        <li className="py-2">
          <button
            type="button"
            className="relative w-full flex justify-center items-center rounded-full border-2 border-dashed border-gray-300 p-2 hover:border-gray-400 focus:ring-offset-2"
            onClick={addItem}
          >
            <PlusIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
          </button>
        </li>
      </ul>
    </div>
  );
}
