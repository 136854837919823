export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-blue-200",
    title: "Kernel Thinning",
    action: "kernelthinning",
    type: "ml",
    subclass: "Machine Learning Method",
    shortdescription: `
Optimally thin a large dataset to retain best approximation with less data.
    `,
    description: `
This is the longer thinning description
    `,
    numTargets: 2,
    numSources: 1,
    targetLabels: ["Large Dataset", "Reduction"],
    sourceLabels: ["Thinned dataset"],
    targetTypes: ["samples", "float"],
    sourceTypes: ["samples"],
    params: {},
  },
};
