import React, { useState } from "react";
import {
  CalendarDaysIcon,
  UserCircleIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/20/solid";
import "@xyflow/react/dist/base.css";
import "./App.css";
import {
  Field,
  Label,
  Description,
  RadioGroup,
  Radio,
} from "@headlessui/react";
import Header from "./Header";
import ReactApexChart from "react-apexcharts";
import HeaderModelBuilder from "./ModelBuilder/HeaderModelBuilder";
import ProgressBarNew from "./ModelBuilder/ProgressBarNew";

import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

import BoxPlot from "./ModelBuilder/BoxPlot";
import RadarPlot from "./ModelBuilder/RadarPlot";

import { generateRandomName } from "./ModelBuilder/randomNameGenerator";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import VersionsList from "./ModelBuilder/VersionsList";

const statuses = {
  offline: "text-gray-500 bg-gray-100",
  online: "text-green-500 bg-green-100",
  error: "text-rose-500 bg-rose-100",
};

const myData = [
  {
    taste: "Robustness",
    myModel: 5,
  },
  {
    taste: "Fit",
    myModel: 4,
  },
  {
    taste: "Complexity",
    myModel: 5,
  },
  {
    taste: "Speed",
    myModel: 2,
  },
  {
    taste: "UQ",
    myModel: 4,
  },
];

const stats = [
  {
    name: "Total Model Calls (7 days)",
    stat: "71,897",
    previousStat: "70,946",
    change: "12%",
    changeType: "increase",
  },
  {
    name: "Est. Training Time",
    stat: "12m",
    previousStat: "56.14%",
    change: "2.02%",
    changeType: "increase",
  },
  {
    name: "Est. Inference Time",
    stat: "0.1s",
    previousStat: "28.62%",
    change: "4.05%",
    changeType: "decrease",
  },
];

const privacySettings = [
  {
    name: "Access for your organisation.",
    description:
      "This model will be available to anyone within your organisation.",
  },
  {
    name: "Private to Project Members",
    description: "Only members of the project can view/copy this model.",
  },
  {
    name: "Private to you",
    description: "You are the only one able to access this model.",
  },
];

function classNames(...classes: (string | undefined | null | false)[]): string {
  return classes.filter(Boolean).join(" ");
}

type ModelBuilderProps = {
  currentUser: any; // Replace `any` with the actual type of currentUser
  useTabs: {
    tabs: any[]; // Replace with the correct type
    currentTab: number;
    addTab: () => void;
    removeTab: () => void;
    selectTab: (tab: number) => void;
  };
};

function ModelBuilder({ currentUser, useTabs }: ModelBuilderProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("summary");
  const [activeModal, setActiveModal] = useState(null);
  const [preSelectedType, setPreSelectedType] = useState("all");
  const [currentProject, setCurrentProject] = useState("p1");
  const { tabs, currentTab, addTab, removeTab, selectTab } = useTabs;
  const currentTabId = tabs[currentTab];
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");

  const [formData, setFormData] = useState({
    modelDescription: "",
    privacy: privacySettings[0], // Default privacy setting
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Handler for privacy selection change
  const handlePrivacyChange = (
    selectedPrivacy: (typeof privacySettings)[0]
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      privacy: selectedPrivacy,
    }));
  };

  const today = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = today.toLocaleDateString("en-US", options);

  return (
    <div className="modelbuilder-form">
      <Header
        setActiveModal={setActiveModal}
        currentUser={currentUser}
        tabs={tabs}
        currentTabId={currentTabId}
        selectTab={selectTab}
      />

      <div className="min-h-full relative pt-16 bg-gray-100 h-full">
        <div className="flex gap-x-2 h-full">
          <div className="flex-none w-1/10"></div>
          <div className="flex-none ml-2 mr-2 w-1/4 py-4 rounded-lg">
            <div className="rounded-lg bg-teal-50 border border-gray-300 shadow-m ring-1 ring-gray-900/5">
              <dl className="flex flex-wrap">
                <div className="flex-auto pl-6 pt-4">
                  <dt className="font-large font-semibold leading-6 text-gray-900">
                    ModelName
                  </dt>
                  <dd className=" text-sm -mt-1 font-small leading-6 text-gray-400">
                    ProjectName
                  </dd>
                  <dd className=" text-sm -mt-1 font-small leading-6 text-gray-400">
                    <i>{generateRandomName()}</i>
                  </dd>
                </div>
                <div className="flex-none self-end px-6 pt-4">
                  <dd className="inline-flex rounded-md bg-green-200 px-2 py-1 text-xs font-medium text-green-800 ring-1 ring-inset ring-green-600/20">
                    Trained
                  </dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                  <dt className="flex-none">
                    <span className="sr-only">Client</span>
                    <UserCircleIcon
                      aria-hidden="true"
                      className="h-6 w-5 text-gray-400"
                    />
                  </dt>
                  <dd className="text-sm font-medium leading-6 text-gray-900">
                    <i>Model Owner : </i>Alex Curren
                  </dd>
                </div>
                <div className="mt-2 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <span className="sr-only">Due date</span>
                    <CalendarDaysIcon
                      aria-hidden="true"
                      className="h-6 w-5 text-gray-400"
                    />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    <time dateTime="todays_date">
                      {" "}
                      <i>Updated - </i>
                      {formattedDate}
                    </time>
                  </dd>
                </div>
              </dl>
              <div className="mt-6"></div>
            </div>

            <div className="mt-4">
              <HeaderModelBuilder
                headerText={"Model Versions"}
                subHeading={"See Related Models"}
              />

              <div className="flex-none px-2 py-1 bg-white rounded-b-lg">
                <VersionsList itemHeight="80px" />
              </div>
            </div>
          </div>

          {/* Model Editing Window */}
          <div className="flex-grow mt-4 rounded-lg">
            <div className="flex justify-center items-center">
              <ProgressBarNew />
            </div>

            {/* STEP 1 - This is the page for Step 1*/}

            <HeaderModelBuilder
              headerText={"Step 1. Add Model Information"}
              subHeading={
                "Provide High-Level Information about the Model you are building"
              }
            />

            <div className="border-l border-b border-r h-full border-gray-300 bg-white">
              <ul role="list" className="divide-y divide-gray-200">
                <Field as="li" className="flex justify-between py-4 px-4">
                  <div className="flex flex-col">
                    <Label
                      as="p"
                      passive
                      className="text-sm font-medium leading-6 text-gray-900"
                    >
                      Model Name
                    </Label>
                    <Description className="text-sm text-gray-500">
                      Provide a unique name for your model.
                    </Description>
                  </div>
                  <div className="flex justify-right">
                    <input
                      value={""} // Provide empty string fallback if null
                      id="modelName"
                      name="modelName"
                      type="text"
                      className={`block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-teal--500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500`}
                    />
                  </div>
                </Field>

                <Field as="li" className="flex justify-between py-4 px-4">
                  <div className="flex flex-col">
                    <Label
                      as="p"
                      passive
                      className="text-sm font-medium leading-6 text-gray-900"
                    >
                      Model Type
                    </Label>
                    <Description className="text-sm text-gray-500">
                      Choose from two types of Model : Predictive and Knowledge.
                    </Description>
                  </div>
                  <div className="flex justify-right">
                    <select
                      id="modelType"
                      name="modelType"
                      defaultValue="Knowledge Model"
                      className="mt-2 block w-120% rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-teal-600 sm:text-sm sm:leading-6"
                    >
                      <option>Predictive Model</option>
                      <option>Knowledge Model</option>
                    </select>
                  </div>
                </Field>

                <Field as="li" className="flex justify-between py-4 mx-4">
                  <div className="flex flex-col mr-4">
                    <Label
                      as="p"
                      passive
                      className="text-sm font-medium leading-6 text-gray-900"
                    >
                      Model Description
                    </Label>
                    <Description className="text-sm text-gray-500">
                      Provide some context about what your model does.
                    </Description>
                  </div>
                  <div className="flex justify-right" style={{ width: "65%" }}>
                    <div className="w-full">
                      <textarea
                        id="description"
                        className="block h-48 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                        placeholder={
                          formData.modelDescription ||
                          "Write context about what the model does"
                        }
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                  </div>
                </Field>

                {/* Sharing / Privacy  */}

                <Field as="li" className="flex justify-between py-4 m-4">
                  <div className="flex flex-col mr-4">
                    <Label
                      as="p"
                      passive
                      className="text-sm font-medium leading-6 text-gray-900"
                    >
                      Sharing / Privacy
                    </Label>
                    <Description className="text-sm text-gray-500">
                      Determine how you model will be shared with your
                      organisation.
                    </Description>
                  </div>
                  <div className="flex justify-right" style={{ width: "65%" }}>
                    <div className="w-full">
                      <RadioGroup
                        value={formData.privacy} // Bind to formData.privacy
                        onChange={handlePrivacyChange} // Update formData when privacy changes
                        className="isolate mt-2 -space-y-px rounded-md bg-white shadow-sm"
                      >
                        {privacySettings.map((setting, settingIdx) => (
                          <Radio
                            key={setting.name}
                            value={setting}
                            aria-label={setting.name}
                            aria-description={setting.description}
                            className={classNames(
                              settingIdx === 0
                                ? "rounded-tl-md rounded-tr-md"
                                : "",
                              settingIdx === privacySettings.length - 1
                                ? "rounded-bl-md rounded-br-md"
                                : "",
                              "group relative flex cursor-pointer border border-gray-200 p-4 focus:outline-none data-[checked]:z-10 data-[checked]:border-teal-200 data-[checked]:bg-teal-50"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className="mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white group-data-[checked]:border-transparent group-data-[checked]:bg-teal-600 group-data-[focus]:ring-2 group-data-[focus]:ring-teal-500 group-data-[focus]:ring-offset-2"
                            >
                              <span className="h-1.5 w-1.5 rounded-full bg-white" />
                            </span>
                            <span className="ml-3 flex flex-col">
                              <span className="block text-sm font-medium text-gray-900 group-data-[checked]:text-teal-900">
                                {setting.name}
                              </span>
                              <span className="block text-sm text-gray-500 group-data-[checked]:text-teal-700">
                                {setting.description}
                              </span>
                            </span>
                          </Radio>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                </Field>
              </ul>
            </div>
          </div>

          {/* Chart and Analytics Section */}
          <div className="flex-none ml-2 w-1/4 py-4 ">
            <div className="mb-4">
              <HeaderModelBuilder
                headerText={"Model Performance"}
                subHeading="How does your trained model perform."
              />
              <dl className="h-96 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-b-lg bg-white border-b border-l border-r border-gray-300">
                <RadarPlot data={myData} />
              </dl>
            </div>

            <HeaderModelBuilder
              headerText={"Model Analytics"}
              subHeading="Data about how your model is being used."
            />
            <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-b-lg bg-white border-b border-l border-r border-gray-300">
              {stats.map((item) => (
                <div key={item.name} className="px-4 py-5 sm:p-6">
                  <dt className="text-base font-normal text-gray-900">
                    {item.name}
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-teal-600">
                      {item.stat}
                      <span className="ml-2 text-sm font-medium text-gray-500">
                        from {item.previousStat}
                      </span>
                    </div>

                    <div
                      className={classNames(
                        item.changeType === "increase"
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800",
                        "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                      )}
                    >
                      {item.changeType === "increase" ? (
                        <ArrowUpIcon
                          aria-hidden="true"
                          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                        />
                      ) : (
                        <ArrowDownIcon
                          aria-hidden="true"
                          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                        />
                      )}

                      <span className="sr-only">
                        {item.changeType === "increase"
                          ? "Increased"
                          : "Decreased"}{" "}
                        by{" "}
                      </span>
                      {item.change}
                    </div>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          {/* Chart and Analytics Section */}
          <div className="flex-none w-1/10"></div>
        </div>
      </div>
    </div>
  );
}

export default ModelBuilder;
