export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-teal-200",
    title: "Conrad",
    action: "conrad",
    type: "rag",
    subclass: "Agentic",
    shortdescription: `
digiLab's fine tuned LLM
    `,
    description: `
    digiLab's fine tuned LLM
    `,
    numTargets: 1,
    numSources: 1,
    targetLabels: ["Instruction"],
    sourceLabels: ["Message Out"],
    targetTypes: ["dict"],
    sourceTypes: ["str"],
    params: {},
  },
};
